<template>
  <div>
    <Nav/>
    <div class="detail-container">
      <div class="bs-flex">
        <div style="margin-left: auto">
          <button class="table-btn" @click="prevBoard">
            <img src="@/assets/images/IA/icon_prev_post.png" alt="list">
            이전글
          </button>
          <button class="table-btn" @click="nextBoard">
            <img src="@/assets/images/IA/icon_next_post.png" alt="list">
            다음글
          </button>
          <button class="table-btn" @click="refList">
            <img src="@/assets/images/IA/icon_list_view.png" alt="list">
            목록
          </button>
        </div>
      </div>
      <div class="detail-sub-container">
        <h5 v-html="referenceInfo.title" class="subTitle"></h5>
        <div class="bs-flex">
          <p v-html="referenceInfo.regDateTime" class="subDate"></p>
          <p class="subViewTitle">등록인 </p>
          <p class="subView" v-if="referenceInfo.writerInfo.writerName != null">{{ referenceInfo.writerInfo.writerName }}</p>
          <p class="subViewTitle">조회수 </p>
          <p v-html="referenceInfo.viewCount + 1" class="subView"></p>
        </div>
        <hr class="line">
        <mdb-input type="textarea" id="editor" class="textarea-desc" outline v-model="referenceInfo.desc" readOnly :rows="10"/>
      </div>
      <div class="change-btn" v-if="referenceInfo.writerInfo.writerUid === userUid">
        <button class="remove-btn" @click="isRemoveModal = true">
          <img src="@/assets/images/IA/icon_delete.png" alt="">
          삭제
        </button>
        <button class="modify-btn" @click="postEdit">
          <img src="@/assets/images/IA/icon_edit.png" alt="">
          수정
        </button>
      </div>
    </div>
    <div class="modal-cover" v-show="isRemoveModal"></div>
    <RemoveDialog @closeModal="closeModal" @removeModal="removeModal" v-if="isRemoveModal === true"/>
  </div>
</template>

<script>
import RemoveDialog from '@/views/common/RemoveDialog.vue';
import {firebase} from "@/firebase/firebaseConfig";
import {getDate, getDateTime} from "@/lib/prettyDate";
import Nav from "@/components/Nav";
import {mdbInput} from "mdbvue";

export default {
  name: "ReferenceDetailView",
  components: {Nav, RemoveDialog, mdbInput},
  props: {
    selected: Object,
    rows: Array,
  },
  data() {
    return {
      fbCollection: 'references',
      userUid: this.$store.state.user.uid,
      postId: this.$route.params.id,
      isRemoveModal: false,
      referenceList: [],
      referenceInfo: {
        orderNo: 0,
        title: '',
        desc: '',
        regDate: '',
        viewCount: 0,
        writerInfo: {
          writerName: '',
          writerUid: '',
          writerEmail: '',
        }
      },
      currentIndex: 0,
      maxIndex: 100,
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      const self = this;
      await self.getCurrentData();
      await self.getDataList();
    },
    async getCurrentData() {
      const self = this;
      const db = firebase.firestore();
      await db.collection(self.fbCollection)
          .doc(self.postId)
          .get()
          .then(async (snapshot) => {
            if (!snapshot.exists) {
              alert('존재하지 않는 게시글 입니다.');
              await self.$router.push('/reference');
              return;
            }
            const _data = snapshot.data();
            const date = new Date(_data.regDate.seconds * 1000);
            _data.regDate = getDate(date);
            _data["regDateTime"] = getDateTime(date);
            self.referenceInfo = _data;
          })
      await self.addViewCount();
    },
    addViewCount() {
      const self = this;
      const db = firebase.firestore();
      db.collection(self.fbCollection)
          .doc(self.postId)
          .update({viewCount: firebase.firestore.FieldValue.increment(+1)},);
    },
    async getDataList() {
      const self = this;
      self.referenceList.splice(0);
      const db = firebase.firestore();
      await db.collection(self.fbCollection)
          .orderBy('orderNo', 'asc')
          .get()
          .then((querySnapshot) => {
            if (querySnapshot.size === 0) {
              return;
            }
            querySnapshot.forEach((doc) => {
              const _data = doc.data();
              _data['docId'] = doc.id;
              self.referenceList.push(_data);
            });
          });
      self.maxIndex = self.referenceList.length;
      self.currentIndex = self.referenceList.findIndex((element) => element.docId === self.postId);


    },
    async prevBoard() {
      const self = this;
      if (self.currentIndex === 0) {
        alert('마지막 글입니다.');
        return;
      }
      const value = self.referenceList[self.currentIndex - 1];
      await self.$router.replace({name: 'referenceDetail', params: {id: value.docId}})
      self.$router.go();
    },

    async nextBoard() {
      const self = this;
      if (self.maxIndex === self.currentIndex + 1) {
        alert('마지막 글입니다.');
        return;
      }
      const value = self.referenceList[self.currentIndex + 1];
      await self.$router.replace({name: 'referenceDetail', params: {id: value.docId}})
      self.$router.go();
    },
    closeModal() {
      const self = this;
      self.isRemoveModal = false;
    },
    async removeModal() {
      const self = this;
      const db = firebase.firestore();
      await db.collection(self.fbCollection)
          .doc(self.postId)
          .delete()
          .then(async () => {
            await self.refList();
          })
    },

    async refList() {
      const self = this;
      await self.$router.push('/reference');
    },
    postEdit() {
      const self = this;
      self.$router.push(`/reference/${self.postId}/edit`)
    }
  }
}
</script>

<style scoped>

.bs-flex {
  display: flex;
}

.bs-flex img {
  height: 18px;
  width: 18px;
}

.table-btn {
  background: #EDEDF1;
  border-radius: 4px;
  font-weight: 400;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  color: #000000;
  border: 0;
  margin-left: 10px;
  padding: 2px 10px;
}

.table-btn img {
  width: 18px;
  height: 18px;
  transform: translateY(-2px);
}

.detail-container {
  width: 1110px;
  margin: 0 auto;
  padding: 40px 0 80px;
  min-height: 565px;
}

.detail-sub-container {
  margin-top: 20px;
  min-height: 307px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  border-radius: 8px;
}

.subTitle {
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.0025em;
  color: #272833;
  margin-bottom: 5px;
  margin-top: 23px;
  margin-left: 32px;
}

.subDate {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.001em;
  color: #8D8D8D;
  margin-left: 32px;
  margin-bottom: 23px;

}

.subViewTitle {
  margin: 0 8px 0 12px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.001em;
  color: #8D8D8D;
}

.subView {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.001em;
  color: #8D8D8D;
}

.line {
  width: 100%;
  margin: 0;
}


.change-btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.change-btn button {
  height: 40px;
  width: 70px;
  border: 0;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  margin-left: 5px;
}

.change-btn button img {
  height: 18px;
  width: 18px;
  margin-right: 4px;
}

.change-btn .remove-btn {
  background: #D23442;
}

.change-btn .modify-btn {
  background: #5AC820;
}

/deep/ .textarea-desc {
  margin-top: 0 !important;
  margin-bottom: 0 !important;

}

/deep/ .textarea-desc textarea {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.0025em;
  color: #272833;
  border: 0 !important;
  resize: none;
  padding: 23px 32px !important;
  margin: 0;
  overflow: overlay;
  height: 552px;
}
</style>
